<template>
  <div>
    <div class="header_holder text-center ma">
      <div class="rounder_top mb-2">
        <div
          class="card_header ubuntu_b primer dark_color"
          v-if="parseInt(this.$route.params.category) !== 0"
        >
          <router-link to="/shop">
            <v-icon class="dark_color">
              fas fa-angle-left
            </v-icon> </router-link
          ><span class="text-center slesh mx-4">/</span>
          <router-link
            :to="'/category/' + main_cat.id"
            class="dark_color ubuntu_b"
          >
            {{ main_cat.name }} </router-link
          ><span class="text-center slesh mx-4">/</span>
          {{ cat.name }}
        </div>
        <div
          class="card_header ubuntu_b primer"
          v-if="parseInt(this.$route.params.category) === 0"
        >
          <p v-if="products.length == 0">Nema rezultata</p>
          <!-- tr -->
          <p v-if="products.length == 1">Jedan je pronađen</p>
          <!-- tr -->
          <p v-if="products.length > 1 && products.length < 5">
            {{ products.length }} je pronađeno
          </p>
          <!-- tr -->
          <p v-if="products.length > 4">{{ products.length }} je pronađeno</p>
          <!-- tr -->
        </div>
      </div>
    </div>
    <v-row>
      <!--<v-col cols="12">
        <div class="text-left mb-3">
            <router-link to="/categories">
                <v-btn color="gray" small text>
                    <v-icon small class="mr-3">fas fa-arrow-left</v-icon>
                    Back to product categories
                </v-btn>
            </router-link>
        </div>
      </v-col>-->
      <v-col
        v-for="(product, index) in products"
        :key="product.id + '-' + product.quantity"
        xl="6"
        lg="6"
        md="12"
        sm="12"
        cols="12"
      >
        <v-hover v-slot:default="{ hover }" close-delay="200">
          <v-card
            :elevation="hover ? 0 : 4"
            class="mx-auto rounder primer"
            outlined
          >
            <div class="naslov clearfix">
              <div class="product_title">{{ product.title }}</div>
              <v-img
                v-if="product.composition.includes(1)"
                :src="require(`@/assets/img/guda.png`)"
                :contain="true"
                width="35"
              ></v-img>
              <v-img
                v-if="product.composition.includes(2)"
                :src="require(`@/assets/img/jaga.png`)"
                :contain="true"
                width="35"
              ></v-img>
              <v-img
                v-if="product.composition.includes(3)"
                :src="require(`@/assets/img/tele.png`)"
                :contain="true"
                width="35"
              ></v-img>
              <v-img
                v-if="product.composition.includes(9)"
                :src="require(`@/assets/img/jagnje.png`)"
                :contain="true"
                width="35"
              ></v-img>
              <div class="product_id">{{ product.id }}</div>
            </div>
            <!--<div v-html="product.prod_desc" class="text-center clearfix"></div>-->
            <div class="text-center cart-action_product">
              <v-btn
                v-if="product.quantity"
                small
                fab
                @click="openNapomenaModal(product)"
                class="btn_minus"
              >
                <v-icon small>
                  {{
                    product.napomena == '' || product.napomena == null
                      ? 'far'
                      : 'fas'
                  }}
                  fa-comment-alt
                </v-icon>
              </v-btn>
              <v-row>
                <v-col cols="6">
                  <div class="mt-1 h32 net_price text-left">
                    {{ product.parent_price | currency }} / {{ product.jm }}
                  </div>
                </v-col>
                <v-col cols="6" class="text-right">
                  <v-btn
                    v-if="!product.quantity"
                    @click="
                      addProductToCart({ id: product.id, step: product.step })
                    "
                    small
                    color="black"
                    class="mt-1 h32 text-right add_to_cart_btn"
                  >
                    Naruči
                  </v-btn>
                  <v-chip
                    v-else
                    class="product_chip"
                    color="transparent"
                    outlined
                  >
                    <v-icon
                      class="plus-minus"
                      small
                      left
                      @click="
                        removeProductFromCart({
                          id: product.id,
                          step: product.step,
                        })
                      "
                      >fas fa-minus</v-icon
                    >
                    <v-btn
                      text
                      class="qty_no_btn"
                      @click="openInput(index)"
                      v-if="open_input !== index"
                      >{{ product.quantity }}</v-btn
                    >
                    <v-text-field
                      v-if="open_input === index"
                      :value="product.quantity"
                      class="qty_no"
                      @keyup="isNumber(product.step, product.id, $event)"
                      @blur="openInput(index)"
                    ></v-text-field>
                    <v-icon
                      class="plus-minus"
                      small
                      right
                      @click="
                        addProductToCart({ id: product.id, step: product.step })
                      "
                      >fas fa-plus</v-icon
                    >
                  </v-chip>
                </v-col>
              </v-row>
              <!--<router-link :to="'/product/'+product.id">
                <v-btn color="secondary" class="details_fab">
                  <span class="mob">More<br>info</span>
                  <span class="desktop">More info</span>
                  <v-icon medium color="white">fas fa-angle-right</v-icon>
                </v-btn>
              </router-link>-->
            </div>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-dialog v-model="NapomenaModal" max-width="320">
      <v-card>
        <v-card-title class="headline">
          Napomena
          <v-icon small color="gray" @click="closeNapomenaModal()"
            >fas fa-times</v-icon
          >
        </v-card-title>
        <v-card-text max-height="400">
          <div class="napomene">
            <v-btn
              color="green"
              @click="note_add(note_o, 'np')"
              v-for="(note_o, index) in product_notes"
              :key="index"
              >{{ note_o }}</v-btn
            >
          </div>
          <v-textarea
            outlined
            v-model="napomena"
            label="Napomena"
            counter="255"
            maxlength="255"
            ref="np"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primer" @click="closeNapomenaModal()">
            Spremi napomenu
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      timer: null,
      open_input: -1,
      NapomenaModal: false,
      item: {},
    };
  },
  props: ['category'],
  computed: {
    ...mapGetters({
      cat_products: 'products/catProducts', //i nju sam promenio
      product_view: 'getProductView',
      userLoggedIn: 'user/loggedIn',
      user_type: 'user/userType',
      userCredentials: 'user/getCredentials',
      categories: 'products/getCategories',
      main_categories: 'products/getMainCategories',
    }),
    products() {
      let prods = this.cat_products(this.$route.params.category);
      if (prods.length) {
        console.log(prods);
        let a = prods
          .map((prod) => {
            let i = this.$store.getters['cart/getCartItems'].find(
              (i) => i.id === prod.id
            ) || { napomena: null };
            return { ...prod, napomena: i.napomena };
          })
          .filter((p) => typeof p.id !== 'undefined');
        return a;
      } else {
        return [];
      }
    },
    cat() {
      return (
        this.categories.find(
          (i) => i.id === parseInt(this.$route.params.category)
        ) || {}
      );
    },
    main_cat() {
      return (
        this.main_categories.find((i) => i.id === parseInt(this.cat.mcat_id)) ||
        {}
      );
    },
    napomena: {
      set(napomena) {
        if (napomena.length > 254) {
          this.$store.commit('message_modal', {
            title: 'Greška',
            text: 'Maksimum 255 znakova',
          });
        } else {
          this.$store.commit('cart/setNapomena', {
            napomena,
            id: this.item.id,
          });
        }
      },
      get() {
        let id = this.item.id;
        let i =
          this.$store.getters['cart/getCartItems'].find((i) => i.id === id) ||
          {};
        let napomena =
          typeof i.napomena === 'undefined' || i.napomena === null
            ? ''
            : i.napomena;
        return napomena;
      },
    },
  },
  methods: {
    ...mapActions('cart', ['addProductToCart', 'removeProductFromCart']),
    openNapomenaModal(item) {
      this.$store.dispatch('cart/get_product_notes', item);
      this.NapomenaModal = true;
      this.item = item;
    },
    closeNapomenaModal() {
      this.NapomenaModal = false;
    },
    openInput(index) {
      if (this.open_input == index) {
        this.open_input = -1;
      } else {
        this.open_input = index;
      }
    },
  },
  beforeCreate() {
    if (this.$store.getters['user/getCredentials'].narucivanje == 0)
      this.$router.push({ name: 'not_auth' });
    this.$store.dispatch(
      'products/getAllProducts' /*this.$route.params.category_id*/
    );
  },
};
</script>
<style scoped>
.slesh {
  width: 60px;
}
.card_header,
.dark_color {
  color: #3b3a39 !important;
}
.headline {
  position: relative;
}
.headline button {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 18px;
}
.napomene {
  overflow-y: auto;
  height: 210px;
}
.btn_minus {
  position: absolute;
  left: -15px;
  bottom: -15px;
}
</style>
